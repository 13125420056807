import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
} from '@angular/core';

import { defaultDialogTitleValue } from '../../../data/constants';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

interface BaseDialogData {
  titleTranslationKey?: string;
  messageTranslationKey?: string;
}

@Component({
  selector: 'app-base-dialog',
  templateUrl: './base-dialog.component.html',
  styleUrls: ['./base-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data?: BaseDialogData
  ) {}
  @Input() displayTitle: string = defaultDialogTitleValue;
}
